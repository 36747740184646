import {
    Button,
    ButtonProps,
    Checkbox,
    FormControlLabel,
    FormGroup,
    styled,
    TextField,
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import { ILoginUser, IPersonalInfoSignUp, ITerminalConditions } from "../models/User";
import { initialPersonalInfoSignUp, initialTerminalConditions } from "../constants/InitialEntities";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { HOME_PATH, DASHBOARD_PATH } from "../constants/GeneralConstants";
import UserService from "../services/UserService";
import { useTranslation } from 'react-i18next';
import { setAccessTokenLS } from "../services/LocalStorage";

const SignUpPage: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [step, setStep] = useState<number>(1);
    const [personalInfo, setPersonalInfo] = useState<IPersonalInfoSignUp>(initialPersonalInfoSignUp);
    const [terminalConditions, setTerminalConditions] = useState<ITerminalConditions[]>(initialTerminalConditions);
    const [isPswVisible, setIsPswVisible] = useState<boolean>(false);
    const [isErrorSignup, setIsErrorSignup] = useState<boolean>(false);

    const handleInputPersonalInfo = (event: ChangeEvent<HTMLInputElement>) => {
        setPersonalInfo({ ...personalInfo, [event.target.name]: event.target.value });
        setIsErrorSignup(false);
    };

    const handleNextStep = () => {
        console.log(personalInfo)
        if (step === 1) {
            if (personalInfo.email.trim() === "" ||
                personalInfo.password.trim() === "") {
                setIsErrorSignup(true);
            } else {
                setStep(step + 1)
            }
        }
    }

    const handleSignUpSubmit = () => {
        if (!isErrorSignup) {
            let data = {
                password: personalInfo.password,
                email: personalInfo.email,
                firstName: personalInfo.firstName,
                lastName: personalInfo.lastName,
                phoneNumber: personalInfo.phoneNumber,
                policy: {
                    termsAndConditions: true,
                    privacyPolicy: true,
                    newsletter: true
                }
            }
            UserService.signupUser(data)
                .then((response) => {
                    setStep(step + 1)
                })
                .catch((e: any) => {
                    setIsErrorSignup(true);
                    // TODO Error message dynamic
                });
        }
    };

    const logInUser = () => {
        let loginUser: ILoginUser = {
            email: personalInfo.email,
            password: personalInfo.password,
            loginType: "password"
        }
        UserService.loginUser(loginUser)
            .then((response) => {
                setAccessTokenLS(response.data.access_token);
                navigate(DASHBOARD_PATH)
            })
            .catch((e: any) => {
                // TODO Specific error for login
                // TODO Error message dynamic
            });
    };

    const ColoredButton = styled(Button)<ButtonProps>(({ theme }) => ({
        backgroundColor: "trasparent",
        border: "1.8px solid #f34616",
        borderRadius: "25px",
        color: "#ffffff",
        fontWeight: 400,
        fontSize: 16,
        textTransform: 'none',
        width: "120px",
        "&:hover": {
            borderColor: "#f34616",
            backgroundColor: "#f34616",
        },
    }));

    const ColoredBackButton = styled(Button)<ButtonProps>(({ theme }) => ({
        backgroundColor: "trasparent",
        border: "1.5px solid #ffffff",
        borderRadius: "25px",
        color: "#ffffff",
        fontWeight: 400,
        fontSize: 16,
        textTransform: 'none',
        width: "80px",
        "&:hover": {
            borderColor: "#FFFFFF",
            backgroundColor: "#FFFFFF",
            color: "#000000",
        },
    }));

    const ColoredButtonLogin = styled(Button)<ButtonProps>(({ theme }) => ({
        backgroundColor: "trasparent",
        border: "1.5px solid #ffffff",
        borderRadius: "25px",
        color: "#ffffff",
        fontWeight: 400,
        fontSize: 16,
        textTransform: 'none',
        width: "80px",
        "&:hover": {
            borderColor: "#1333de",
            backgroundColor: "#1333de",
        },
    }));

    return (
        <div className="container col-12 m-0 p-0 nmw">
            <div className="col-12 row m-0 p-0 d-flex justify-content-center">
                <div className="back-service-button p-0">
                    <ColoredBackButton
                        className="button-size button-font-size"
                        onClick={() => navigate(HOME_PATH)}
                    >
                        {t('button_back')}
                    </ColoredBackButton>
                </div>
                <div className="col-12 m-0 p-0 little-screen-no-padding">
                    <div className="col-12 center logo-box">
                        <div className="logo"></div>
                    </div>
                </div>
                <div className="col-xl-5 col-md-8 col-12 title-box pt-0">
                    <div className="col-12 px-3 pb-4 p-0">
                        {step === 1 && <div className="col-12 center mb-2">
                            <div className="icon-user-add"></div>
                            <div className="sub-title nunito mt-4 mb-2">{t('subtitle_personal_info')}</div>
                        </div>}
                        {step === 2 && <div className="col-12 center mb-2">
                            <div className="icon-check"></div>
                            <div className="sub-title nunito mt-4 mb-2">{t('subtitle_terminal_conditions')}</div>
                        </div>}
                        {step === 3 && <div className="col-12 center mb-2">
                            <div className="icon-mail"></div>
                            <div className="sub-title nunito mt-4">{t('subtitle_mail_check')}</div>
                            <div className="descr-panel nunito mt-4">{t('text_mail_check')}</div>
                            <div className="descr-panel nunito mt-1">{t('text_mail_check_2')}</div>
                        </div>}
                        {step === 1 && <div className="col-12 px-1 m-0 p-0 row">
                            <div className="col-12 col-sm-6 mt-3 m-0">
                                <TextField
                                    label={t('label_first_name')}
                                    size="small"
                                    sx={{ input: { color: '#ffffff' }, label: { color: "#ffffff" }, fieldset: { borderColor: "#ffffff !important", border: "1.5px solid" } }}
                                    style={{ width: '100%' }}
                                    onChange={handleInputPersonalInfo}
                                    name="firstName"
                                    value={personalInfo.firstName}
                                />
                            </div>
                            <div className="col-12 col-sm-6 mt-3 m-0">
                                <TextField
                                    label={t('label_last_name')}
                                    size="small"
                                    sx={{ input: { color: '#ffffff' }, label: { color: "#ffffff" }, fieldset: { borderColor: "#ffffff !important", border: "1.5px solid" } }}
                                    style={{ width: '100%' }}
                                    onChange={handleInputPersonalInfo}
                                    name="lastName"
                                    value={personalInfo.lastName}
                                />
                            </div>

                            {/* <div className="col-12 panel mt-4 m-0 fiscal-panel row">
                                <div className="nav-flex-box center title-box p-0" style={{ position: "relative" }}>
                                    <div className="icon-shield-little"></div>
                                </div>
                                <div className="col m-0 p-0">
                                    <div className="sub-title nunito title-box pb-0">The fiscal code is required</div>
                                    <div className="col-12 descr-box">
                                        <span className="descr-panel">We need the fiscal code to verify your identity.</span>
                                    </div>
                                    <div className="col-12 descr-box">
                                        <span className="descr-panel">It will be used only for this check and It won't send to any services.</span>
                                    </div>
                                    <div className="col-12 mt-3 m-0 p-0 center">
                                        <TextField
                                            label="Fiscal code"
                                            size="small"
                                            sx={{ input: { color: isErrorLogin ? '#f34616' : '#ffffff' }, label: { color: "#ffffff" }, fieldset: { borderColor: "#ffffff !important", border: "1.5px solid" } }}
                                            style={{ width: '100%' }}
                                            onChange={handleInputPersonalInfo}
                                            name="fiscalCode"
                                        />
                                    </div>
                                    <div className="error-message center">{isErrorLogin ? "Email or password are incorrect" : " "}</div>
                                </div>
                            </div> */}
                        </div>}
                        {step === 1 && <div className="col-12 center mb-2 logo-box pb-0">
                            <div className="icon-setting"></div>
                            <div className="sub-title nunito mt-4">{t('subtitle_settings')}</div>
                        </div>}
                        {step === 1 && <div className="col-12 px-1 m-0 p-0 row">
                            <div className="col-12 col-sm-6 mt-3 m-0">
                                <TextField
                                    label={t('label_email') + " *"}
                                    size="small"
                                    sx={{ input: { color: isErrorSignup && personalInfo.email.trim() === "" ? '#f34616' : '#ffffff' }, label: { color: isErrorSignup && personalInfo.email.trim() === "" ? '#f34616' : "#ffffff" }, fieldset: { borderColor: "#ffffff !important", border: "1.5px solid" } }}
                                    style={{ width: '100%' }}
                                    onChange={handleInputPersonalInfo}
                                    name="email"
                                    value={personalInfo.email}
                                />
                            </div>
                            <div className="col-12 col-sm-6 mt-3 m-0">
                                <TextField
                                    label={t('label_phone_number')}
                                    size="small"
                                    sx={{ input: { color: '#ffffff' }, label: { color: "#ffffff" }, fieldset: { borderColor: "#ffffff !important", border: "1.5px solid" } }}
                                    style={{ width: '100%' }}
                                    onChange={handleInputPersonalInfo}
                                    name="phoneNumber"
                                    value={personalInfo.phoneNumber}
                                />
                            </div>
                            <div className="col-12 col-sm-6 mt-3 m-0">
                                <TextField
                                    label={t('label_password') + " *"}
                                    size="small"
                                    type={isPswVisible ? "text" : "password"}
                                    sx={{ input: { color: isErrorSignup && personalInfo.password.trim() === "" ? '#f34616' : '#ffffff' }, label: { color: isErrorSignup && personalInfo.password.trim() === "" ? '#f34616' : "#ffffff" }, fieldset: { borderColor: "#ffffff !important", border: "1.5px solid" } }}
                                    style={{ width: '100%' }}
                                    onChange={handleInputPersonalInfo}
                                    name="password"
                                    value={personalInfo.password}
                                />
                            </div>
                            <div className="col-12 mt-1 m-0 py-0">
                                <span className="descr-panel pointer link" onClick={() => setIsPswVisible(!isPswVisible)}>{isPswVisible ? t('label_hide_password') : t('label_show_password')}</span>
                            </div>
                        </div>}

                        {step === 2 && <div className="col-12 px-1 m-0 p-0 row">
                            <FormGroup>
                                {
                                    terminalConditions.map((tc, indexTc) => (
                                        <FormControlLabel key={indexTc} control={
                                            <Checkbox sx={{
                                                color: "white",
                                                '&.Mui-checked': {
                                                    color: "#2FB45A",
                                                },
                                            }}
                                            />
                                        } label={tc.label} className="mt-4 tc-text" />
                                    ))
                                }
                            </FormGroup>
                        </div>}

                        <div className="row col-12 px-1 justify-content-end m-0">
                            {step !== 3 && <> <div className="col-6 mt-4 m-0">
                                {step === 2 && <ColoredBackButton
                                    className="button-size button-font-size"
                                    onClick={() => setStep(step - 1)}
                                >
                                    {t('button_back')}
                                </ColoredBackButton>}
                            </div>
                                <div className="col-6 mt-4 m-0 right">
                                    {step === 1 && <ColoredBackButton
                                        className="button-size button-font-size"
                                        onClick={handleNextStep}
                                    >
                                        {t('button_next')}
                                    </ColoredBackButton>}
                                    {step === 2 && <ColoredButton
                                        className="button-size button-font-size"
                                        onClick={() => handleSignUpSubmit()}
                                    >
                                        {t('button_sign_up')}
                                    </ColoredButton>}
                                </div> </>}

                            {step === 3 && <div className="col-12 mt-4 m-0 center">
                                <ColoredButtonLogin
                                    className="button-size button-font-size"
                                    onClick={logInUser}
                                >
                                    {t('button_login')}
                                </ColoredButtonLogin>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUpPage;
