import './App.css';
import DashboardPage from './pages/DashboardPage';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import SignUpPage from './pages/SignUpPage';
import { ADD_SERVICE_PATH, HOME_PATH, DASHBOARD_PATH, SIGNUP_PATH } from './constants/GeneralConstants';
import HomePage from './pages/HomePage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddServicePage from './pages/AddServicePage';

function App() {
  const theme = createTheme({
    palette: {
      primary: { main: "#ffffff", contrastText: "#ffffff" },
      secondary: { main: "#03a9f4", contrastText: "#fff" }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            element={<SignUpPage></SignUpPage>}
            path={SIGNUP_PATH}
          ></Route>
          <Route
            element={<DashboardPage></DashboardPage>}
            path={DASHBOARD_PATH}
          ></Route>
          <Route
            element={<AddServicePage></AddServicePage>}
            path={ADD_SERVICE_PATH}
          ></Route>
          <Route element={<HomePage></HomePage>} path={HOME_PATH}></Route>
          <Route path="*" element={<Navigate to={HOME_PATH} replace />}></Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>

  );
}

export default App;
