import { ChangeEvent, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { NavBar } from "../components/NavBar";
import { HOME_PATH, INFO_TYPE_ALL, INFO_TYPE_PERSONAL, INFO_TYPE_SETTINGS, PAGE_TYPE_PERS_INFO } from "../constants/GeneralConstants";
import { Alert, Autocomplete, Box, Button, ButtonProps, Paper, TextField, styled } from "@mui/material";
import { IPersonalInfo, ISettingsInfo } from "../models/User";
import { initialPersonalInfo, initialSettingsInfo } from "../constants/InitialEntities";
import UserService from "../services/UserService";
import { useTranslation } from "react-i18next";
import PlaceInfoService from "../services/PlaceInfoService";

const PersonalInfoPanel: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [personalInfo, setPersonalInfo] = useState<IPersonalInfo>(initialPersonalInfo);
    const [settingsInfo, setSettingsInfo] = useState<ISettingsInfo>(initialSettingsInfo);
    const [settingsInfoEdited, setSettingsInfoEdited] = useState<ISettingsInfo>(initialSettingsInfo);
    const [isErrorLogin, setIsErrorLogin] = useState<boolean>(false);
    const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);
    const [countryList, setCountryList] = useState<any[]>([])
    const [stateList, setStateList] = useState<any[]>([])
    const [cityList, setCityList] = useState<any[]>([])
    const [countrySelected, setCountrySelected] = useState<any>(null)
    const [stateSelected, setStateSelected] = useState<any>(null)
    const [citySelected, setCitySelected] = useState<any>(null)
    const [isEditingPersonalInfo, setIsEditingPersonalInfo] = useState<boolean>(false);
    const [isEditingSettingsInfo, setIsEditingSettingsInfo] = useState<boolean>(false);
    const [isAlertEditingVerifiedUser, setIsAlertEditingVerifiedUser] = useState<boolean>(false);
    const [isAlertEditingVerifiedEmail, setIsAlertEditingVerifiedEmail] = useState<boolean>(false);
    const [isAlertNotEditingEmail, setIsAlertNotEditingEmail] = useState<boolean>(false);

    useEffect(() => {
        fetchData(INFO_TYPE_ALL);
    }, []);

    useEffect(() => {
        if (countrySelected && countrySelected.isoCode) {
            getStateList(countrySelected.isoCode)
        }
    }, [countrySelected]);

    useEffect(() => {
        if (stateSelected && stateSelected.isoCode) {
            getCityList(countrySelected.isoCode, stateSelected.isoCode)
        }
    }, [stateSelected]);

    const fetchData = (type: string) => {
        UserService.getUser()
            .then((response: any) => {
                let res = response.data;
                // TODO è giusto il campo per vedere se l'email è stata verificata ?
                setIsEmailVerified(res.auth.verified);
                if (type === INFO_TYPE_ALL || type === INFO_TYPE_PERSONAL) {
                    let persInfo: IPersonalInfo = {
                        firstName: res.user.firstName ? res.user.firstName : "",
                        lastName: res.user.lastName ? res.user.lastName : "",
                        birthdate: res.user.birthdate ? res.user.birthdate : "",
                        birthplaceInfo: {
                            city: res.user.birthplaceInfo ? res.user.birthplaceInfo.city : null,
                            country: res.user.birthplaceInfo ? res.user.birthplaceInfo.country : null,
                            state: res.user.birthplaceInfo ? res.user.birthplaceInfo.state : null,
                        },
                        fiscalCode: res.user.fiscalCode,
                    }
                    setPersonalInfo(persInfo);
                    getCountryList();
                }

                if (type === INFO_TYPE_ALL || type === INFO_TYPE_SETTINGS) {
                    let settInfo: ISettingsInfo = {
                        email: res.user.email ? res.user.email : "",
                        phoneNumber: res.user.phoneNumber ? res.user.phoneNumber : "",
                    }
                    setSettingsInfo(settInfo);
                    setSettingsInfoEdited(settInfo);
                }
            })
            .catch((e: any) => {
                navigate(HOME_PATH)
            });
    }

    const getCountryList = () => {
        // reset the state and city selected
        setCountrySelected(null);
        setStateSelected(null);
        setCitySelected(null);
        setCountryList([]);
        setStateList([]);
        setCityList([]);

        PlaceInfoService.getCountries()
            .then((response: any) => {
                let res = response.data;
                setCountryList(res)
                if (personalInfo.birthplaceInfo.country) {
                    let country = res.filter((c: any) => c.isoCode === personalInfo.birthplaceInfo.country)
                    if (country.length > 0) {
                        setCountrySelected(country[0])
                        getStateList(personalInfo.birthplaceInfo.country);
                    }
                }
            })
            .catch((e: any) => { });
    }

    const getStateList = (countryIso: string) => {
        PlaceInfoService.getStates(countryIso)
            .then((response: any) => {
                let res = response.data;
                setStateList(res)
                if (personalInfo.birthplaceInfo.state) {
                    let state = res.filter((c: any) => c.isoCode === personalInfo.birthplaceInfo.state)
                    if (state.length > 0) {
                        setStateSelected(state[0]);
                        getCityList(personalInfo.birthplaceInfo.country, personalInfo.birthplaceInfo.state)
                    }
                }
            })
            .catch((e: any) => { });
    }

    const getCityList = (countryIso: string, stateIso: string) => {
        PlaceInfoService.getCities(countryIso, stateIso)
            .then((response: any) => {
                let res = response.data;
                setCityList(res)
                if (personalInfo.birthplaceInfo.city) {
                    let city = res.filter((c: any) => c.name === personalInfo.birthplaceInfo.city)
                    if (city.length > 0) {
                        setCitySelected(city[0])
                    }
                }
            })
            .catch((e: any) => { });
    }

    const saveInfo = (type: string) => {
        debugger;
        let data: any = {};
        if (type === INFO_TYPE_PERSONAL) {
            data.firstName = personalInfo.firstName;
            data.lastName = personalInfo.lastName;
        } else if (type === INFO_TYPE_SETTINGS) {
            if (settingsInfoEdited.email !== settingsInfo.email) {
                data.email = settingsInfoEdited.email
            }
            if (settingsInfoEdited.phoneNumber !== settingsInfo.phoneNumber) {
                data.phoneNumber = settingsInfoEdited.phoneNumber
            }
        }

        UserService.editUser(data)
            .then((response: any) => {
                // TODO Mostrare alert SUCCESS

                if (type === INFO_TYPE_SETTINGS) {
                    // Per refreshare sezione Setting
                    handleCancelSettingsInfo();
                } else if (type === INFO_TYPE_PERSONAL) {
                    // Per refreshare sezione Personal
                    handleCancelPersonalInfo();
                }
            })
            .catch((e: any) => {
                // TODO Mostrare errore salvataggio
            });
    }

    const handleInputPersonalInfo = (event: ChangeEvent<HTMLInputElement>) => {
        setIsEditingPersonalInfo(true);
        // TODO Aggiungere if utenza Verified
        setIsAlertEditingVerifiedUser(true);

        setPersonalInfo({ ...personalInfo, [event.target.name]: event.target.value });
    };

    const handleInputSettingsInfo = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === "email") {
            // const emailNew = event.target.value.trim();
            // if (emailNew !== settingsInfo.email && isEmailVerified) {
            //     setIsAlertEditingVerifiedEmail(true);
            // }
            // if (settingsInfoEdited.phoneNumber !== settingsInfo.phoneNumber || emailNew !== settingsInfo.email) {
            //     setIsEditingSettingsInfo(true);
            // } else {
            //     setIsEditingSettingsInfo(false);
            // }

            // setSettingsInfoEdited({ ...settingsInfoEdited, [event.target.name]: emailNew });
            
            // TODO da togliere quando si potrà modificare l'email
            setIsAlertNotEditingEmail(true);
            setTimeout(() => {
                setIsAlertNotEditingEmail(false);
            }, 3000);
        }
        if (event.target.name === "phoneNumber") {
            const numeri = event.target.value.trim().replace(/\D/g, "");
            if (numeri !== settingsInfo.phoneNumber || settingsInfoEdited.email !== settingsInfo.email) {
                setIsEditingSettingsInfo(true);
            } else {
                setIsEditingSettingsInfo(false);
            }
            setSettingsInfoEdited({ ...settingsInfoEdited, [event.target.name]: numeri });
        }
    };

    const handleCountryChange = (newValue: any) => {
        let birthplaceInfo = personalInfo.birthplaceInfo;
        birthplaceInfo.country = newValue ? newValue.isoCode : "";

        // reset the state selected
        birthplaceInfo.state = "";
        setStateSelected(null);
        setStateList([]);

        // reset the city selected
        birthplaceInfo.city = "";
        setCitySelected(null);
        setCityList([]);

        setPersonalInfo({ ...personalInfo, birthplaceInfo });
        setCountrySelected(newValue);
        setIsEditingPersonalInfo(true);
    }

    const handleStateChange = (newValue: any) => {
        let birthplaceInfo = personalInfo.birthplaceInfo;
        birthplaceInfo.state = newValue ? newValue.isoCode : "";

        // reset the city selected
        birthplaceInfo.city = "";
        setCitySelected(null);
        setCityList([]);

        setPersonalInfo({ ...personalInfo, birthplaceInfo });
        setStateSelected(newValue);
        setIsEditingPersonalInfo(true);
    }

    const handleCityChange = (newValue: any) => {
        let birthplaceInfo = personalInfo.birthplaceInfo;
        birthplaceInfo.city = newValue ? newValue.name : "";
        setPersonalInfo({ ...personalInfo, birthplaceInfo });
        setCitySelected(newValue);
        setIsEditingPersonalInfo(true);
    }

    const handleSavePersonalInfo = () => {
        console.log(personalInfo)
        saveInfo(INFO_TYPE_PERSONAL)
    }

    const handleCancelPersonalInfo = () => {
        fetchData(INFO_TYPE_PERSONAL);
        setIsEditingPersonalInfo(false);
        setIsAlertEditingVerifiedUser(false);
    }

    const handleSaveSettingsInfo = () => {
        console.log(settingsInfo)
        saveInfo(INFO_TYPE_SETTINGS)
    }

    const handleCancelSettingsInfo = () => {
        fetchData(INFO_TYPE_SETTINGS);
        setIsEditingSettingsInfo(false);
        setIsAlertEditingVerifiedEmail(false);
    }

    const ColoredButtonSave = styled(Button)<ButtonProps>(({ theme }) => ({
        backgroundColor: "trasparent",
        border: "1.5px solid #ffffff",
        borderRadius: "25px",
        color: "#ffffff",
        fontWeight: 400,
        fontSize: 16,
        textTransform: 'none',
        width: "80px",
        "&:hover": {
            borderColor: "#1333de",
            backgroundColor: "#1333de",
        },
    }));

    const ColoredCancelButton = styled(Button)<ButtonProps>(({ theme }) => ({
        backgroundColor: "trasparent",
        border: "1.5px solid #ffffff",
        borderRadius: "25px",
        color: "#ffffff",
        fontWeight: 400,
        fontSize: 16,
        textTransform: 'none',
        width: "80px",
        "&:hover": {
            borderColor: "#FFFFFF",
            backgroundColor: "#FFFFFF",
            color: "#000000",
        },
    }));

    return (
        <div className="col-12 m-0 pers-info-container row">
            {!isEmailVerified &&
                <div className="col-12 pt-3 m-0">
                    <Alert
                        className="col-12"
                        severity="error"
                    >
                        {t('message_email_to_verify')}
                    </Alert>
                </div>}
            <div className="col-12 col-lg-6 pt-3 m-0">
                <div className="col-12 panel pb-3 px-2 row m-0">
                    <div className="col-12 row m-0 mt-2 p-0">
                        <div className="card-contact-flex-box p-0 m-auto">
                            <div className="icon-user-orange-info"></div>
                        </div>
                        <div className="col m-0 title-card-info-box">
                            <span className="title-panel nunito">{t('subtitle_personal_info')}</span>
                        </div>
                    </div>
                    <div className="col-12 m-0 p-0 row">
                        <div className="col-12 col-sm-6 col-lg-12 col-xl-6 mt-3 m-0">
                            <TextField
                                label={t('label_first_name')}
                                size="small"
                                sx={{ input: { color: isErrorLogin ? '#f34616' : '#ffffff' }, label: { color: "#ffffff" }, fieldset: { borderColor: "#ffffff !important", border: "1.5px solid" } }}
                                style={{ width: '100%' }}
                                onChange={handleInputPersonalInfo}
                                name="firstName"
                                value={personalInfo.firstName ? personalInfo.firstName : ""}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-12 col-xl-6 mt-3 m-0">
                            <TextField
                                label={t('label_last_name')}
                                size="small"
                                sx={{ input: { color: isErrorLogin ? '#f34616' : '#ffffff' }, label: { color: "#ffffff" }, fieldset: { borderColor: "#ffffff !important", border: "1.5px solid" } }}
                                style={{ width: '100%' }}
                                onChange={handleInputPersonalInfo}
                                name="lastName"
                                value={personalInfo.lastName ? personalInfo.lastName : ""}
                            />
                        </div>
                        {/* <div className="col-12 col-sm-6 col-lg-12 col-xl-6 mt-3 m-0">
                            <TextField
                                label={t('label_birthdate')}
                                size="small"
                                sx={{ input: { color: isErrorLogin ? '#f34616' : '#ffffff' }, label: { color: "#ffffff" }, fieldset: { borderColor: "#ffffff !important", border: "1.5px solid" } }}
                                style={{ width: '100%' }}
                                onChange={handleInputPersonalInfo}
                                name="birthdate"
                                value={personalInfo.birthdate ? personalInfo.birthdate : ""}
                            />
                        </div> */}
                    </div>
                    {/* <div className="col-12 m-0 mt-3 p-0 row">
                        <div className="col-12 col-sm-6 col-lg-12 col-xl-6 mt-3 m-0">
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={countryList}
                                size="small"
                                sx={{ input: { color: isErrorLogin ? '#f34616' : '#ffffff' }, label: { color: "#ffffff" }, fieldset: { borderColor: "#ffffff !important", border: "1.5px solid" }, '& .MuiSvgIcon-root': { color: '#ffffff' } }}
                                style={{ width: '100%' }}
                                PaperComponent={(props) => (
                                    <Paper {...props} sx={{ backgroundColor: '#000000', color: '#ffffff' }} />
                                )}
                                getOptionLabel={(option) => option.name + " (" + option.isoCode + ")"}
                                renderOption={(props, option) => (
                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                        <img
                                            loading="lazy"
                                            width="20"
                                            srcSet={`https://flagcdn.com/w40/${option.isoCode.toLowerCase()}.png 2x`}
                                            src={`https://flagcdn.com/w20/${option.isoCode.toLowerCase()}.png`}
                                        />
                                        {option.name} ({option.isoCode})
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('label_country')}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                                value={countrySelected}
                                onChange={(event: any, newValue: string | null) => {
                                    handleCountryChange(newValue);
                                }}
                            />
                        </div>
                        {stateList.length > 0 &&
                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6 mt-3 m-0">
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={stateList}
                                    size="small"
                                    sx={{ input: { color: isErrorLogin ? '#f34616' : '#ffffff' }, label: { color: "#ffffff" }, fieldset: { borderColor: "#ffffff !important", border: "1.5px solid" }, '& .MuiSvgIcon-root': { color: '#ffffff' } }}
                                    style={{ width: '100%' }}
                                    PaperComponent={(props) => (
                                        <Paper {...props} sx={{ backgroundColor: '#000000', color: '#ffffff' }} />
                                    )}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                            {option.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('label_state')}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                    value={stateSelected}
                                    onChange={(event: any, newValue: string | null) => {
                                        handleStateChange(newValue);
                                    }}
                                />
                            </div>
                        }
                        {cityList.length > 0 &&
                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6 mt-3 m-0">
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={cityList}
                                    size="small"
                                    sx={{ input: { color: isErrorLogin ? '#f34616' : '#ffffff' }, label: { color: "#ffffff" }, fieldset: { borderColor: "#ffffff !important", border: "1.5px solid" }, '& .MuiSvgIcon-root': { color: '#ffffff' } }}
                                    style={{ width: '100%' }}
                                    PaperComponent={(props) => (
                                        <Paper {...props} sx={{ backgroundColor: '#000000', color: '#ffffff' }} />
                                    )}
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                            {option.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('label_city')}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                    value={citySelected}
                                    onChange={(event: any, newValue: string | null) => {
                                        handleCityChange(newValue);
                                    }}
                                />
                            </div>
                        }
                    </div> */}
                    {/* <div className="col-12 m-0 p-0 row justify-content-start">
                        <div className="col col-sm-6 col-lg col-xl-6 mt-3 m-0">
                            <TextField
                                label={t('label_fiscal_code')}
                                size="small"
                                sx={{ input: { color: isErrorLogin ? '#f34616' : '#ffffff' }, label: { color: "#ffffff" }, fieldset: { borderColor: "#ffffff !important", border: "1.5px solid" } }}
                                style={{ width: '100%' }}
                                onChange={handleInputPersonalInfo}
                                name="fiscalCode"
                                value={personalInfo.fiscalCode ? personalInfo.fiscalCode : ""}
                            />
                        </div>
                        <div className="card-contact-flex-box p-0 mt-3" style={{ position: "relative" }}>
                            <div className="icon-shield-info"></div>
                        </div>
                    </div> */}
                    {(isAlertEditingVerifiedUser && isEditingPersonalInfo) &&
                        <div className="col-12 pt-3 m-0">
                            <Alert
                                className="col-12"
                                severity="error"
                            >
                                {t('message_edit_user_verified')}
                            </Alert>
                        </div>}
                    {isEditingPersonalInfo && <div className="col-12 m-0 mt-3 p-0 row justify-content-end">
                        <div className="button-flex-box right p-0">
                            <ColoredCancelButton
                                className="button-size button-font-size"
                                onClick={handleCancelPersonalInfo}
                            >
                                {t('button_cancel')}
                            </ColoredCancelButton>
                        </div>
                        <div className="button-flex-box right">
                            <ColoredButtonSave
                                className="button-size button-font-size"
                                onClick={handleSavePersonalInfo}
                            >
                                {t('button_save')}
                            </ColoredButtonSave>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="col-12 col-lg-6 pt-3 m-0">
                <div className="col-12 panel pb-4 px-2 row m-0">
                    <div className="col-12 row m-0 mt-2 p-0">
                        <div className="card-contact-flex-box p-0 m-auto">
                            <div className="icon-setting-info"></div>
                        </div>
                        <div className="col m-0 title-card-info-box">
                            <span className="title-panel nunito">{t('subtitle_settings')}</span>
                        </div>
                    </div>
                    <div className="col-12 m-0 p-0 row">
                        <div className="col-12 col-sm-6 col-lg-12 col-xl-6 mt-3 m-0">
                            <TextField
                                label={t('label_email')}
                                size="small"
                                sx={{ input: { color: isErrorLogin ? '#f34616' : '#ffffff' }, label: { color: "#ffffff" }, fieldset: { borderColor: "#ffffff !important", border: "1.5px solid" } }}
                                style={{ width: '100%' }}
                                onChange={handleInputSettingsInfo}
                                name="email"
                                value={settingsInfoEdited.email}
                            />
                        </div>
                        <div className="col-12 col-sm-6 col-lg-12 col-xl-6 mt-3 m-0">
                            <TextField
                                label={t('label_phone_number')}
                                size="small"
                                sx={{ input: { color: isErrorLogin ? '#f34616' : '#ffffff' }, label: { color: "#ffffff" }, fieldset: { borderColor: "#ffffff !important", border: "1.5px solid" } }}
                                style={{ width: '100%' }}
                                onChange={handleInputSettingsInfo}
                                name="phoneNumber"
                                value={settingsInfoEdited.phoneNumber}
                            />
                        </div>
                    </div>
                    {(isAlertNotEditingEmail) &&
                        <div className="col-12 pt-3 m-0">
                            <Alert
                                className="col-12"
                                severity="error"
                            >
                                {t('message_not_edit_email')}
                            </Alert>
                        </div>}
                    {(isAlertEditingVerifiedEmail && isEditingSettingsInfo) &&
                        <div className="col-12 pt-3 m-0">
                            <Alert
                                className="col-12"
                                severity="error"
                            >
                                {t('message_edit_email_verified')}
                            </Alert>
                        </div>}
                    <div className="col-12 m-0 p-0 row justify-content-start">
                        {isEditingSettingsInfo && <div className="col-12 m-0 mt-3 p-0 row justify-content-end">
                            <div className="button-flex-box right p-0">
                                <ColoredCancelButton
                                    className="button-size button-font-size"
                                    onClick={handleCancelSettingsInfo}
                                >
                                    {t('button_cancel')}
                                </ColoredCancelButton>
                            </div>
                            <div className="button-flex-box right">
                                <ColoredButtonSave
                                    className="button-size button-font-size"
                                    onClick={handleSaveSettingsInfo}
                                >
                                    {t('button_save')}
                                </ColoredButtonSave>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>

            {/* <div className="col-12 col-lg-6 pt-3 m-0">
                <div className="col-12 panel pb-4 px-2 row m-0">
                    <div className="card-contact-flex-box p-0 m-auto">
                        <div className="icon-business-info"></div>
                    </div>
                    <div className="col m-0 title-card-info-box">
                        <span className="title-panel nunito">Business Info</span>
                    </div>
                    <div className="col-12 m-0 p-0 row">
                        <span className="tc-text">Work in progress</span>
                    </div>
                </div>
            </div> */}
        </div>

    );
};

export default PersonalInfoPanel;
