// PATH
export const DASHBOARD_PATH = "/dashboard";
export const SIGNUP_PATH = "/signup";
export const ADD_SERVICE_PATH = "/service"
export const HOME_PATH = "/"

// TYPE
export const PAGE_TYPE_PERS_INFO = "pers-info";
export const PAGE_TYPE_SERVICES = "services";
export const INFO_TYPE_PERSONAL = "personal-info";
export const INFO_TYPE_SETTINGS = "settings-info";
export const INFO_TYPE_ALL = "all-info";

// CONTACT
export const CONTACT_NUMBER = "+39 3924162864"
export const CONTACT_INSTA = "https://www.instagram.com/"
export const CONTACT_EMAIL = "giuliofaggian@gmail.com"

// LOCAL STORAGE
export const LS_ACCESS_TOKEN = "SPHEERY_access_token";