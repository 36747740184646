import { isMobile } from "react-device-detect";
import { FormControlLabel, IconButton, Switch, SwitchProps, TextField, ThemeProvider, Tooltip, alpha, createTheme, styled } from "@mui/material";
import DocSearchIcon from "../assets/icons/doc-search.svg";
import DeleteIcon from "../assets/icons/delete.svg";
import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";

const nodes = [
    {
        id: '0',
        name: 'Api App',
        lastLogin: new Date(2020, 1, 15),
        info: 'TASK',
        isActive: true,
    },
];

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

const ServicesPanel: React.FC = () => {
    const data = { nodes };
    const theme = useTheme([
        getTheme(),
        {
            Table: `
            --data-table-library_grid-template-columns:  minmax(160px, 1fr) minmax(160px, 200px) minmax(100px, 140px) repeat(2, 60px);
          `,
        },
    ]);
    const COLUMNS = [
        { label: "NAME", renderCell: (item: any) => item.name },
        {
            label: "LAST LOGIN",
            renderCell: (item: any) =>
                item.lastLogin.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                }),
        },
        {
            label: "STATUS",
            renderCell: (item: any) => (
                // <Switch checked={item.isActive}
                // onChange={() => handleChangeStatus(item)} />
                <IOSSwitch sx={{ m: 1 }} checked={item.isActive} onChange={() => handleChangeStatus(item)}/>
            ),
        },
        {
            label: "", renderCell: (item: any) => (
                <Tooltip title="View">
                    <IconButton
                        sx={{ backgroundColor: "rgba(119, 126, 154, 0.4)" }}
                        onClick={() => {
                            handleClickInfo(item)
                        }}
                        className="icon-button-table"
                        size={isMobile ? "small" : "medium"}
                    >
                        <img className="navbar-img" src={DocSearchIcon} />
                    </IconButton>
                </Tooltip>
            ),
        },
        {
            label: "", renderCell: (item: any) => (
                <Tooltip title="Delete">
                    <IconButton
                        sx={{ backgroundColor: "rgba(119, 126, 154, 0.4)" }}
                        onClick={() => {
                            handleClickInfo(item.id)
                        }}
                        className="icon-button-table"
                        size={isMobile ? "small" : "medium"}
                    >
                        <img className="navbar-img" src={DeleteIcon} />
                    </IconButton>
                </Tooltip>)
        },
    ];

    const handleClickInfo = (item: any) => {
        console.log(item)
    }

    const handleChangeStatus = (item: any) => {
        console.log(item)
    }

    return (
        <div className="col-12 m-0 pers-info-container row">
            <div className="col-12 pt-3 m-0">
                <div className="col-12 panel pb-4 px-2 row m-0">
                    <div className="card-contact-flex-box p-0 m-auto">
                        <div className="icon-service-purple"></div>
                    </div>
                    <div className="col m-0 title-card-info-box">
                        <span className="title-panel nunito">Services</span>
                    </div>
                    <div className="col-12 m-0 p-0 row">
                        <div className="col-12 m-0 p-0">
                            <CompactTable columns={COLUMNS} data={data} theme={theme}
                                layout={{ custom: true }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ServicesPanel;
