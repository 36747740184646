import { IEmailUserContact, ILoginUser, IPersonalInfo, IPersonalInfoSignUp, ISettingsInfo, ITerminalConditions } from "../models/User";

export const initialLoginUser: ILoginUser = {
    email: "",
    password: "",
    loginType: "password"
};

export const initialPersonalInfo: IPersonalInfo = {
    firstName: "",
    lastName: "",
    birthdate: "",
    birthplaceInfo: {
        city: "",
        country: "",
        state: "",
    },
    fiscalCode: "",
};

export const initialSettingsInfo: ISettingsInfo = {
    email: "",
    phoneNumber: "",
};

export const initialPersonalInfoSignUp: IPersonalInfoSignUp = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
};

export const initialEmailUserContact: IEmailUserContact = {
    name: "",
    email: "",
    message: "",
};

export const initialTerminalConditions: ITerminalConditions[] = [
    {
        label: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi sint nam eum quia, consequatur adipisci dignissimos laudantium sunt est ut aliquid impedit. Eum fugit soluta quidem in perferendis ad provident!",
        isChecked: false,
    },
    {
        label: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi sint nam eum quia, consequatur adipisci dignissimos laudantium sunt est ut aliquid impedit. Eum fugit soluta quidem in perferendis ad provident!",
        isChecked: false,
    },
    {
        label: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi sint nam eum quia, consequatur adipisci dignissimos laudantium sunt est ut aliquid impedit. Eum fugit soluta quidem in perferendis ad provident!",
        isChecked: false,
    },
    {
        label: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi sint nam eum quia, consequatur adipisci dignissimos laudantium sunt est ut aliquid impedit. Eum fugit soluta quidem in perferendis ad provident!",
        isChecked: false,
    }
]