import { useNavigate } from "react-router-dom";
import { DASHBOARD_PATH, HOME_PATH, PAGE_TYPE_PERS_INFO, PAGE_TYPE_SERVICES } from "../constants/GeneralConstants";

interface NavBarBottom {
    page: string;
    onChangePage: (page: string) => void;
}

export const NavBarBottom: React.FC<NavBarBottom> = ({ page, onChangePage }) => {
    const navigate = useNavigate();

    const navTo = (typePage: string) => {
        onChangePage(typePage);
    }

    const handleLogout = () => {
        navigate(HOME_PATH)
    }

    return (
        <div className="m-0 p-0 nav-box-bottom">
            <div className="row m-0 px-2 justify-content-center" style={{ gap: "0.5rem" }}>
                {page === PAGE_TYPE_PERS_INFO ? (
                    <div
                        className="nav-icon-box center nav-selected-bottom p-0 default"
                    >
                        <div className="icon-user-white-bottom"></div>
                    </div>
                ) : (
                    <div
                        className="nav-icon-box center nav-item-bottom p-0 pointer"
                        onClick={() => {
                            navTo(PAGE_TYPE_PERS_INFO);
                        }}
                    >
                        <div className="icon-user-bottom"></div>
                    </div>
                )}

                {/* {page === PAGE_TYPE_SERVICES ? (
                    <div
                        className="nav-icon-box center nav-selected-bottom p-0 default"
                    >
                        <div className="icon-service-white-bottom"></div>
                    </div>
                ) : (
                    <div
                        className="nav-icon-box center nav-item-bottom p-0 pointer"
                        onClick={() => {
                            navTo(PAGE_TYPE_SERVICES);
                        }}
                    >
                        <div className="icon-service-bottom"></div>
                    </div>
                )} */}

                <div
                    className="nav-icon-box center nav-item-bottom p-0 pointer"
                    onClick={handleLogout}
                >
                    <div className="icon-logout-bottom"></div>
                </div>
            </div>
        </div>
    );
};