import {
    Button,
    ButtonProps,
    styled,
    TextField,
    Tooltip,
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import { IEmailUserContact, ILoginUser } from "../models/User";
import { initialEmailUserContact, initialLoginUser } from "../constants/InitialEntities";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { ADD_SERVICE_PATH, CONTACT_INSTA, CONTACT_NUMBER, HOME_PATH, DASHBOARD_PATH, SIGNUP_PATH } from "../constants/GeneralConstants";
import copy from 'clipboard-copy';
import { useTranslation } from 'react-i18next';

const AddServicePage: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isErrorEmailContact, setIsErrorEmailContact] = useState<boolean>(false);
    const [emailUserContact, setEmailUserContact] = useState<IEmailUserContact>(initialEmailUserContact);

    const handleInputEmailContact = (event: ChangeEvent<HTMLInputElement>) => {
        setIsErrorEmailContact(false);
        setEmailUserContact({ ...emailUserContact, [event.target.name]: event.target.value });
    };

    const handleNavigateBack = () => {
        navigate(HOME_PATH)
    };

    const handleSendEmailContact = () => {
        setIsErrorEmailContact(true)
    }

    const handleContact = (type: string) => {
        if (type === CONTACT_NUMBER) {
            handleCopyContact(type);
        }else if(type === CONTACT_INSTA){
            window.open(type, '_blank');
        }
    }

    const handleCopyContact = async (type: string) => {
        try {
            await copy(type);
        } catch (error) {
            console.error(t('message_copy_error'), error);
        }
    };

    const ColoredBackButton = styled(Button)<ButtonProps>(({ theme }) => ({
        backgroundColor: "trasparent",
        border: "1.5px solid #ffffff",
        borderRadius: "25px",
        color: "#ffffff",
        fontWeight: 400,
        fontSize: 16,
        textTransform: 'none',
        width: "80px",
        "&:hover": {
            borderColor: "#FFFFFF",
            backgroundColor: "#FFFFFF",
            color: "#000000",
        },
    }));

    const ColoredSendButton = styled(Button)<ButtonProps>(({ theme }) => ({
        backgroundColor: "trasparent",
        border: "1.5px solid #ffffff",
        borderRadius: "25px",
        color: "#ffffff",
        fontWeight: 400,
        fontSize: 16,
        textTransform: 'none',
        width: "80px",
        "&:hover": {
            borderColor: "#1333de",
            backgroundColor: "#1333de",
        },
    }));


    return (
        <div className="container col-12 m-0 p-0 nmw">
            <div className="col-12 row m-0 pb-4 little-screen-no-padding">
                <div className="back-service-button p-0">
                    <ColoredBackButton
                        className="button-size button-font-size"
                        onClick={() => handleNavigateBack()}
                    >
                        {t('button_back')}
                    </ColoredBackButton>
                </div>
                <div className="col-12 center logo-box">
                    <div className="logo"></div>
                </div>

                {/* MEDIUM SIZE */}
                <div className="col-12 m-0 p-0 row hide-big-service">
                    <div className="col-md-4 animate__animated animate__zoomIn">
                        <div className="icon-people"></div>
                    </div>
                    <div className="col-md-8 row m-0">
                        <div className="col-12 mt-3">
                            <span className="title-panel nunito">{t('title_card_verified')}</span>
                        </div>
                        <div className="col-12 descr-box">
                            <span className="descr-panel">{t('text_verified')}</span>
                        </div>
                    </div>
                </div>
                <div className="col-12 service-box p-0 row hide-big-service">
                    <div className="col-md-8 row m-0">
                        <div className="col-12 mt-3">
                            <span className="title-panel nunito">{t('title_card_info')}</span>
                        </div>
                        <div className="col-12 descr-box">
                            <span className="descr-panel">{t('text_info')}</span>
                        </div>
                    </div>
                    <div className="col-md-4 animate__animated animate__zoomIn">
                        <div className="icon-archive"></div>
                    </div>
                </div>
                <div className="col-12 service-box p-0 row hide-big-service">
                    <div className="col-md-4 animate__animated animate__zoomIn">
                        <div className="icon-pc"></div>
                    </div>
                    <div className="col-md-8 row m-0">
                        <div className="col-12 mt-3">
                            <span className="title-panel nunito">{t('title_card_trustable')}</span>
                        </div>
                        <div className="col-12 descr-box">
                            <span className="descr-panel">{t('text_trustable')}</span>
                        </div>
                    </div>
                </div>

                {/* LITTLE SIZE */}
                <div className="col-12 col-md-4 p-0 m-0 row hide-little-service animate__animated animate__zoomIn">
                    <div className="col-12">
                        <div className="icon-people"></div>
                    </div>
                    <div className="col-12 row m-0">
                        <div className="col-12 center">
                            <span className="title-panel nunito">{t('title_card_verified')}</span>
                        </div>
                        <div className="col-12 descr-box center">
                            <span className="descr-panel">{t('text_verified')}</span>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 p-0 row hide-little-service service-little-box animate__animated animate__zoomIn">
                    <div className="col-12">
                        <div className="icon-archive"></div>
                    </div>
                    <div className="col-12 row m-0">
                        <div className="col-12 center">
                            <span className="title-panel nunito">{t('title_card_info')}</span>
                        </div>
                        <div className="col-12 descr-box center">
                            <span className="descr-panel">{t('text_info')}</span>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 p-0 row hide-little-service service-little-box animate__animated animate__zoomIn">
                    <div className="col-12">
                        <div className="icon-pc"></div>
                    </div>
                    <div className="col-12 row m-0">
                        <div className="col-12 center">
                            <span className="title-panel nunito">{t('title_card_trustable')}</span>
                        </div>
                        <div className="col-12 descr-box center">
                            <span className="descr-panel">{t('text_trustable')}</span>
                        </div>
                    </div>
                </div>


                <div className="col-12 service-subtitle-box center">
                    <span className="sub-title nunito">{t('subtitle_what')}</span>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-5 p-0 mt-4 m-0">
                    <div className="col-12 panel p-0 pb-3 mt-3 transition-card">
                        <div className="col-12 title-box pt-4 center">
                            <div className="title-panel nunito">{t('subtitle_send_mail')}</div>
                        </div>
                        <div className="col-10 col-sm-8 col-md-6 col-lg-10 col-xl-8 m-0 p-0 mx-auto">
                            <div className="col-12 m-0 p-0">
                                <TextField
                                    label={t('label_name')}
                                    size="small"
                                    sx={{ input: { color: isErrorEmailContact ? '#f34616' : '#ffffff' }, label: { color: "#ffffff" }, fieldset: { borderColor: "#ffffff !important", border: "1.5px solid" } }}
                                    style={{ width: '100%' }}
                                    onChange={handleInputEmailContact}
                                    name="name"
                                />
                            </div>
                            <div className="col-12 mt-3 m-0 p-0">
                                <TextField
                                    label={t('label_email')}
                                    size="small"
                                    type="email"
                                    sx={{ input: { color: isErrorEmailContact ? '#f34616' : '#ffffff' }, label: { color: "#ffffff" }, fieldset: { borderColor: "#ffffff !important", border: "1.5px solid" } }}
                                    style={{ width: '100%' }}
                                    onChange={handleInputEmailContact}
                                    name="email"
                                />
                            </div>
                            <div className="col-12 mt-3 m-0 p-0">
                                <TextField
                                    label={t('label_message')}
                                    size="small"
                                    sx={{ input: { color: isErrorEmailContact ? '#f34616' : '#ffffff' }, label: { color: "#ffffff" }, fieldset: { borderColor: "#ffffff !important", border: "1.5px solid" } }}
                                    style={{ width: '100%' }}
                                    onChange={handleInputEmailContact}
                                    name="message"
                                />
                            </div>
                            <div className="col-12 mt-4 pb-1 m-0 p-0 center">
                                <ColoredSendButton
                                    className="button-size button-font-size"
                                    onClick={() => handleSendEmailContact()}
                                >
                                    {t('button_send')}
                                </ColoredSendButton>
                            </div>
                            <div className="error-message center">{isErrorEmailContact ? t('message_mail_not_correct') : " "}</div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-7 p-0 mt-4 m-0 row align-self-start">
                    <div className="col-12 p-0" style={{ height: 160 }}>
                        <div className="icon-message"></div>
                    </div>
                    <div className="col-12 row m-0 justify-content-md-center">
                        <div className="col-12 center">
                            <span className="title-panel nunito">{t('subtitle_other_contact')}</span>
                        </div>
                        <div className="col-12 col-lg-12 col-xl-5 m-0 mt-3 p-0 contact-card-div">
                            <Tooltip title={t('label_copy')}>
                                <div className="row col card-contact-whats m-auto pointer" onClick={() => handleContact(CONTACT_NUMBER)}>
                                    <div className="card-contact-flex-box p-0">
                                        <div className="icon-whats"></div>
                                    </div>
                                    <div className="col"><span className="card-contact-text pointer">+39 3478891436</span></div>
                                </div>
                            </Tooltip>
                        </div>
                        <div className="col-12 col-lg-12 col-xl-5 m-0 mt-3 p-0 contact-card-div">
                            <Tooltip title={t('label_go_to')}>
                                <div className="row col card-contact-insta m-auto pointer" onClick={() => handleContact(CONTACT_INSTA)}>
                                    <div className="card-contact-flex-box p-0">
                                        <div className="icon-insta"></div>
                                    </div>
                                    <div className="col"><span className="card-contact-text pointer">@spheery</span></div>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default AddServicePage;
