import axios from "axios";
import { APPLICATION_JSON, BASE_URL } from "./constants/ApiSettings";
import { LS_ACCESS_TOKEN } from "./constants/GeneralConstants";
import { setLogoutLS } from "./services/LocalStorage";
import UserService from "./services/UserService";

const http = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": APPLICATION_JSON,
  },
});

http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(LS_ACCESS_TOKEN);
    if (config.url?.includes("auth")) {
      config.headers!["Authorization"] = "";
      delete axios.defaults.headers.common["Authorization"];
    } else {
      if (token) {
        config.headers!["Authorization"] = "Bearer " + token;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
