import { useNavigate } from "react-router-dom";
import { DASHBOARD_PATH, HOME_PATH, PAGE_TYPE_PERS_INFO, PAGE_TYPE_SERVICES } from "../constants/GeneralConstants";

interface INavBar {
    page: string;
    onChangePage: (page: string) => void;
}

export const NavBar: React.FC<INavBar> = ({ page, onChangePage }) => {
    const navigate = useNavigate();

    const navTo = (typePage: string) => {
        onChangePage(typePage);
    }

    const handleLogout = () => {
        navigate(HOME_PATH)
    }

    return (
        <div className="col-12 m-0 p-0 nav-box panel">
            <div className="row m-0 px-2">
                <div className="col-12 navbar-logo"></div>

                {page === PAGE_TYPE_PERS_INFO ? (
                    <div
                        className="col-12 center nav-selected p-1 default"
                    >
                        <div className="icon-user-white"></div>
                        <span className="nav-title-selected">Personal info</span>
                    </div>
                ) : (
                    <div
                        className="col-12 center nav-item p-1 pointer"
                        onClick={() => {
                            navTo(PAGE_TYPE_PERS_INFO);
                        }}
                    >
                        <div className="icon-user"></div>
                        <span className="nav-title">Personal info</span>
                    </div>
                )}

                {/* {page === PAGE_TYPE_SERVICES ? (
                    <div
                        className="col-12 center nav-selected p-1 mt-1 default"
                    >
                        <div className="icon-service-white"></div>
                        <span className="nav-title-selected">Services</span>
                    </div>
                ) : (
                    <div
                        className="col-12 center nav-item p-1 mt-1 pointer"
                        onClick={() => {
                            navTo(PAGE_TYPE_SERVICES);
                        }}
                    >
                        <div className="icon-service"></div>
                        <span className="nav-title">Services</span>
                    </div>
                )} */}

                <div
                    className="col-12 center nav-item p-1 mt-1 pointer"
                    onClick={handleLogout}
                >
                    <div className="icon-logout"></div>
                    <span className="nav-title">Logout</span>
                </div>
            </div>
        </div>
    );
};