import { ChangeEvent, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { NavBar } from "../components/NavBar";
import { PAGE_TYPE_PERS_INFO, PAGE_TYPE_SERVICES } from "../constants/GeneralConstants";
import PersonalInfoPanel from "../components/PersonalInfoPanel";
import ServicesPanel from "../components/ServicesPanel";
import { NavBarBottom } from "../components/NavBarBottom";

const DashboardPage: React.FC = () => {
    const [pageSelected, setPageSelected] = useState<string>(PAGE_TYPE_PERS_INFO);

    const onChangePage = (page: string) => {
        setPageSelected(page)
    }

    return (
        <div className="container col-12 m-0 p-0 nmw">
            <div className="row m-0">
                {isMobile ?
                    <NavBarBottom page={pageSelected} onChangePage={onChangePage}></NavBarBottom> : 
                    <div className="nav-flex-box pt-3">
                        <NavBar page={pageSelected} onChangePage={onChangePage}></NavBar>
                    </div>}
                <div className="col m-0 p-0">
                    {pageSelected === PAGE_TYPE_PERS_INFO && <PersonalInfoPanel></PersonalInfoPanel>}
                    {pageSelected === PAGE_TYPE_SERVICES && <ServicesPanel></ServicesPanel>}
                </div>
            </div>

        </div>

    );
};

export default DashboardPage;
