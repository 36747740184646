import {
    Button,
    ButtonProps,
    styled,
    TextField,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { ILoginUser } from "../models/User";
import { initialLoginUser } from "../constants/InitialEntities";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { ADD_SERVICE_PATH, DASHBOARD_PATH, HOME_PATH, SIGNUP_PATH } from "../constants/GeneralConstants";
import UserService from "../services/UserService";
import { setAccessTokenLS } from "../services/LocalStorage";
import { useTranslation } from 'react-i18next';

const HomePage: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isStarting, setIsStarting] = useState<boolean>(true);
    const [startingAnimation, setStartingAnimation] = useState<number>(0);
    const [loginUser, setLoginUser] = useState<ILoginUser>(initialLoginUser);
    const [isForgotPsw, setIsForgotPsw] = useState<boolean>(false);
    const [isErrorLogin, setIsErrorLogin] = useState<boolean>(false);
    const [isErrorForgotPsw, setIsErrorForgotPsw] = useState<boolean>(false);
    const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setIsStarting(false);
        }, 900);
    }, []);

    const logInUser = () => {
        if (loginUser.email.trim() === "" || loginUser.password.trim() === "") {
            setIsErrorLogin(true);
            return
        }

        if (!isForgotPsw) {
            UserService.loginUser(loginUser)
                .then((response) => {
                    setIsEmailSent(false);
                    setIsErrorLogin(false);
                    setIsErrorForgotPsw(false);

                    setAccessTokenLS(response.data.access_token);
                    navigate(DASHBOARD_PATH)
                })
                .catch((e: any) => {
                    setIsErrorLogin(true);
                    // TODO Error message dynamic
                });
        }
    };

    const sendForgotPsw = () => {
        if (loginUser.email.trim() === "") {
            setIsErrorForgotPsw(true);
            return
        }

        if (isForgotPsw) {
            UserService.sendForgotPsw(loginUser.email)
                .then((response) => {
                    // TODO BE check email is valid 
                    // TODO BE check email is saved on db 
                    setIsEmailSent(true);
                    setIsErrorLogin(false);
                    setIsErrorForgotPsw(false);
                })
                .catch((e: any) => {
                    setIsErrorForgotPsw(true);
                    // TODO Error message dynamic
                });
        }
    };

    const handleInputLogin = (event: ChangeEvent<HTMLInputElement>) => {
        setIsErrorLogin(false);
        setIsErrorForgotPsw(false);
        setIsEmailSent(false);
        setLoginUser({ ...loginUser, [event.target.name]: event.target.value });
    };

    const handleChangeAction = () => {
        setIsForgotPsw(!isForgotPsw);
        setIsErrorLogin(false);
        setIsErrorForgotPsw(false);
        setIsEmailSent(false);
    }

    const ColoredButton = styled(Button)<ButtonProps>(({ theme }) => ({
        backgroundColor: "trasparent",
        border: "1.5px solid #ffffff",
        borderRadius: "25px",
        color: "#ffffff",
        fontWeight: 400,
        fontSize: 16,
        textTransform: 'none',
        width: "80px",
        "&:hover": {
            borderColor: "#1333de",
            backgroundColor: "#1333de",
        },
    }));

    return (
        <div className="container col-12 m-0 p-0 nmw">
            <div className="center pt-3 pb-3 logo-box col-12">
                <div className="logo-login"></div>
            </div>
            <div className="col-12 row m-0 px-2 pb-4">
                <div className="col-md-6 col-sm-12">
                    <div className="col-12 panel px-4 pb-3 mt-3 login-panel">
                        <div className="col-12 center title-box">
                            <div className="icon-user-white"></div>
                            <div className="title-panel nunito">{isForgotPsw ? t('subtitle_forgot_password') : t('subtitle_welcome_back')}</div>
                        </div>
                        {isForgotPsw ? <div className="col-10 col-sm-8 col-md-6 m-auto p-0">
                            <div className="col-12 m-0 p-0 center">
                                {isEmailSent ? <div className="tc-text-green nunito"> {t('message_mail_sent')}<br></br> {t('message_mail_sent_check')}</div> : <div className="tc-text nunito">{t('message_mail_to_send')}</div>}
                            </div>
                            <div className="col-12 mt-3 m-0 p-0 center">
                                <TextField
                                    label={t('label_email')}
                                    size="small"
                                    sx={{ input: { color: isErrorForgotPsw ? '#f34616' : '#ffffff' }, label: { color: isErrorForgotPsw ? '#f34616' : "#ffffff" }, fieldset: { borderColor: "#ffffff !important", border: "1.5px solid" } }}
                                    style={{ width: '100%' }}
                                    onChange={handleInputLogin}
                                    name="email"
                                />
                            </div>
                            <div className="col-12 mt-1 m-0 p-0 right">
                                <span className="descr-panel pointer link" onClick={handleChangeAction}>{t('label_cancel')}</span>
                            </div>
                            <div className="col-12 mt-4 pb-1 m-0 p-0 center">
                                <ColoredButton
                                    className="button-size button-font-size"
                                    onClick={sendForgotPsw}
                                >
                                    {t('button_send')}
                                </ColoredButton>
                            </div>
                            <div className="error-message center">{isErrorForgotPsw && loginUser.email.trim() !== "" ? t('mail_not_correct') : " "}</div>
                        </div> : <div className="col-10 col-sm-8 col-md-6 m-auto p-0">
                            <div className="col-12 m-0 p-0 center">
                                <TextField
                                    label={t('label_email')}
                                    size="small"
                                    sx={{ input: { color: isErrorLogin ? '#f34616' : '#ffffff' }, label: { color: isErrorLogin ? '#f34616' : "#ffffff" }, fieldset: { borderColor: "#ffffff !important", border: "1.5px solid" } }}
                                    style={{ width: '100%' }}
                                    onChange={handleInputLogin}
                                    name="email"
                                />
                            </div>
                            <div className="col-12 mt-3 m-0 p-0 center">
                                <TextField
                                    label={t('label_password')}
                                    size="small"
                                    type="password"
                                    sx={{ input: { color: isErrorLogin ? '#f34616' : '#ffffff' }, label: { color: isErrorLogin ? '#f34616' : "#ffffff" }, fieldset: { borderColor: "#ffffff !important", border: "1.5px solid" } }}
                                    style={{ width: '100%' }}
                                    onChange={handleInputLogin}
                                    name="password"
                                />
                            </div>
                            <div className="col-12 mt-1 m-0 p-0 right">
                                <span className="descr-panel pointer link" onClick={handleChangeAction}>{t('label_forgot_password')}</span>
                            </div>
                            <div className="col-12 mt-4 pb-1 m-0 p-0 center">
                                <ColoredButton
                                    className="button-size button-font-size"
                                    onClick={logInUser}
                                >
                                    {t('button_login')}
                                </ColoredButton>
                            </div>
                            <div className="error-message center">{isErrorLogin && (loginUser.email.trim() !== "" && loginUser.password.trim() !== "") ? t('message_mail_password_not_correct') : " "}</div>
                        </div>}


                    </div>
                </div>
                <div className="col-md-6 col-12 row m-auto p-0">
                    <div className="col-12 mt-3 hide-big">
                        <span className="title-panel nunito">{t('homepage_slogan')}</span>
                    </div>
                    <div className="col-md-12 col-lg-6 col-6 mt-3">
                        <div className={isStarting ? "col-12 panel-azure-light click-card px-2 pb-2 mt-3 animate__animated animate__zoomIn" : "col-12 panel-azure-light click-card px-2 pb-2 mt-3"} onClick={() => navigate(SIGNUP_PATH)}>
                            <div className="col-12 center title-box">
                                <div className="icon-user-add"></div>
                                <div className="sub-title nunito">{t('label_sign_up')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6 col-6 mt-3">
                        <div className={isStarting ? "col-12 panel click-card px-2 pb-2 mt-3 animate__animated animate__zoomIn" : "col-12 panel click-card px-2 pb-2 mt-3"} onClick={() => navigate(ADD_SERVICE_PATH)}>
                            <div className="col-12 center title-box">
                                <div className="icon-service-add"></div>
                                <div className="sub-title nunito">{t('label_add_it')}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-5 center">
                    <span className="sub-title nunito">{t('subtitle_why')}</span>
                </div>
                <div className="col-12 m-0 p-0 row">
                    <div className="col-md-4 col-12 mt-3">
                        <div className={isStarting ? "col-12 panel-green p-4 animate__animated animate__zoomIn" : "col-12 panel-green p-4"} onMouseEnter={() => setStartingAnimation(1)} onMouseLeave={() => setStartingAnimation(0)}>
                            <div className={startingAnimation === 1 ? "icon-share animate__animated animate-rotate-on-hover" : "icon-share"}></div>
                            <div className="col-12 title-box center">
                                <span className="title-panel nunito">{t('title_card_one_for_all')}</span>
                            </div>
                            <div className="col-12 descr-box center">
                                <span className="descr-panel">{t('text_one_for_all')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-12 mt-3">
                        <div className={isStarting ? "col-12 panel-azure p-4 animate__animated animate__zoomIn" : "col-12 panel-azure p-4"} onMouseEnter={() => setStartingAnimation(2)} onMouseLeave={() => setStartingAnimation(0)}>
                            <div className={startingAnimation === 2 ? "icon-shield animate__animated animate-flip-on-hover" : "icon-shield"}></div>
                            <div className="col-12 title-box center">
                                <span className="title-panel nunito">{t('title_card_reliable')}</span>
                            </div>
                            <div className="col-12 descr-box center">
                                <span className="descr-panel">{t('text_reliable')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-12 mt-3">
                        <div className={isStarting ? "col-12 panel-purple p-4 animate__animated animate__zoomIn" : "col-12 panel-purple p-4"} onMouseEnter={() => setStartingAnimation(3)} onMouseLeave={() => setStartingAnimation(0)}>
                            <div className={startingAnimation === 3 ? "icon-eye animate__animated animate-bounce-on-hover" : "icon-eye"}></div>
                            <div className="col-12 title-box center">
                                <span className="title-panel nunito">{t('title_card_transparent')}</span>
                            </div>
                            <div className="col-12 descr-box center">
                                <span className="descr-panel">{t('text_transparent')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default HomePage;
