import {
  LS_ACCESS_TOKEN,
} from "../constants/GeneralConstants";

export const getStorageValue = (key: string) => {
  const saved = localStorage.getItem(key);
  return saved;
};

export const setAccessTokenLS = (access_token: string) => {
  localStorage.setItem(LS_ACCESS_TOKEN, access_token);
};

export const setLogoutLS = () => {
  localStorage.removeItem(LS_ACCESS_TOKEN);
  window.location.reload();
};
