import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en', // Lingua di fallback
    interpolation: {
      escapeValue: false, // React gestisce la sicurezza
    },
    resources: {
      en: {
        translation: require('./languages/en.json'),
      },
      it: {
        translation: require('./languages/it.json'),
      },
    },
  });

export default i18n;