import http from "../http-common";

const getCountries = () => {
    return http.get<any>("geo/countries");
}

const getStates = (countryIso: string) => {
    return http.get<any>("geo/states?countryIso=" + countryIso);
}

const getCities = (countryIso: string, stateIso: string) => {
    return http.get<any>("geo/cities?countryIso=" + countryIso + "&stateIso=" + stateIso);
}

const PlaceInfoService = {
    getCountries,
    getStates,
    getCities
};

export default PlaceInfoService;
