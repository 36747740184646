import http from "../http-common";

const loginUser = (user: any) => {
  return http.post<any>("auth/login/", user);
};

const signupUser = (user: any) => {
  return http.post<any>("auth/signup/", user);
};

const getUser = () => {
  return http.get<any>("user/info");
}

const editUser = (user: any) => {
  return http.patch<any>("user/info", user);
}

const sendForgotPsw = (email: string) => {
  let data = {
    email,
  };
  return http.post<any>("auth/request/password-reset/", data);
};






const checkEmail = (token: string) => {
  return http.get<any>("verfy/" + token);
};



const resetPsw = (email: string) => {
  let data = {
    email,
  };
  return http.post<any>("request/password-reset/", data);
};

const refreshTokens = (token: any) => {
  return http.post<any>("auth/token/refresh/", token);
};



const verifyTokenResetPsw = (uidb64: string, token: string) => {
  return http.get<any>("auth/reset-password/" + uidb64 + "/" + token + "/");
};

const resetPswComplete = (dataForm: any) => {
  return http.post<any>("auth/password-reset-complete/", dataForm);
};

const UserService = {
  loginUser,
  signupUser,
  getUser,
  editUser,
  sendForgotPsw,

  checkEmail,
  refreshTokens,
  resetPsw,
  verifyTokenResetPsw,
  resetPswComplete,
};

export default UserService;
